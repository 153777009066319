import { createApp } from "vue";
import { createPinia } from "pinia";

import { useRegisterSW } from "virtual:pwa-register/vue";

import router from "@/router";
import "@/router/guard";

import registerExtend from "@/extend";

import { tap } from "@/utils";

import App from "@/views/App.vue";

// import "ant-design-vue/dist/antd.less";

useRegisterSW({
    onRegistered(r) {
        r && setInterval(() => r.update(), 3600 * 1000)
    }
});

const app = createApp(App).use(createPinia()).use(router);

tap(app, (app) => registerExtend(app))
    // .use(Antd)
    .mount("#app");
