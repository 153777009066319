import { hasAction } from "@/utils";

function checkAction(el, binding) {
    const { value } = binding;

    if (value && Array.isArray(value)) {
        if (value.length > 0 && !hasAction(value)) {
            el.parentNode && el.parentNode.removeChild(el);
        }
    } else {
        throw new Error(`need action! Like v-action="['module-view']"`);
    }
}

export default {
    mounted(el, binding) {
        checkAction(el, binding);
    },
    updated(el, binding) {
        checkAction(el, binding);
    },
};
