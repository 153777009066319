import ActionDirective from "@/directives/action";
import Icon from "@/views/Components/Icon.vue";
import * as Icons from "./icon";
import { useUserStore, TOKEY_KEY } from "@/store/user";
import { hasAction } from "@/utils";

export default (app) => {
    Object.entries(Icons).forEach(([name, component]) => {
        app.component(name, component);
    });

    app.component("EstateIcon", Icon);

    app.directive("action", ActionDirective);

    // 全局属性填充
    app.config.globalProperties = Object.assign({}, app.config.globalProperties, {
        hasAction
    });

    // FIXME 代码优化
    const token = localStorage.getItem(TOKEY_KEY);
    if (token) {
        useUserStore().token = token;
    }
};
