<template>
    <a-config-provider :locale="zhCN" :theme="theme">
        <router-view />
    </a-config-provider>
</template>

<script setup name="APP">
import zhCN from 'ant-design-vue/es/locale/zh_CN';
const theme = {
    token: {
        colorPrimary: '#eb4432'
    }
}
</script>