import http from "@/utils/http";

export const getDict = (dicts) =>
    http.get(`/dictionary`, { params: { dicts } });

/**
 * 文件上传
 * @param {String} type
 * @param {File} file
 * @returns
 */
export const fileUpload = (type, file) => {
    const formData = new FormData();
    formData.append("type", type);
    formData.append("file", file);

    return http.post(`/upload`, formData, {
        "Content-Type": "multipart/form-data",
    });
};

/**
 * 发送注册验证码
 */
export const sendRegisterEmail = (params) =>
    http.post(`/v1.0/register/send`, params);

/**
 * 账号登录
 * @param {*} params
 * @returns
 */
export const login = (params) => {
    return http.post(`/v1.0/auth/login`, params);
};

/**
 * 三方登录
 * @param {*} platform
 * @param {*} secret
 * @returns
 */
export const loginRedirect = (platform, secret) => {
    return http.post(`/v1.0/auth/login-redirect`, {
        platform,
        secret,
    });
};

export const register = (params) => http.post(`/v1.0/auth/register`, params);

/**
 * 获取当前登录用户信息
 * @returns
 */
export const getUserInfo = () => http.get(`/v1.0/auth/user`);

export const getRankVideo = () => http.get(`/v1.0/rank`);

export const getClassicsVideo = () => http.get(`/v1.0/classics`);

export const getHotVideo = () => http.get(`/v1.0/hot`);

export const getNewVideo = () => http.get(`/v1.0/news`);

/**
 * 获取分类列表
 * @returns
 */
export const getCategories = () => http.get(`/v1.0/categories`);

/**
 * 获取系统标签列表
 * @returns
 */
export const getTags = () => http.get(`/v1.0/tags`);

/**
 * 获取指定标签视频列表 - 分页
 * @param {*} params
 * @returns
 */
export const getTagVideoList = (params) =>
    http.get(`/v1.0/tag/${params.slug}`, { params });

/**
 * 获取指定分类视频列表 - 分页
 * @param {*} params
 * @returns
 */
export const getCateVideoList = (params) =>
    http.get(`/v1.0/category/${params.slug}`, { params });

/**
 * 获取指定关键字视频列表 - 分页
 */
export const getSearchByKeyword = (params) =>
    http.get(`/v1.0/search`, { params });

/**
 * 获取视频详情
 * @param {*} id
 * @returns
 */
export const getDetail = (id) => http.get(`/v1.0/detail/${id}`);


/**
 * 获取视频下载信息
 * @param {*} id
 * @returns
 */
export const getDownload = (id) => http.get(`/v1.0/download/${id}`);

/**
 * 发送下载信息
 * @param {*} params 
 * @returns 
 */
export const sendDownloadInfo = (params) => http.post(`/v1.0/video/send`, params);

/**
 * 视频点赞
 * @param {*} id
 * @returns
 */
export const videoAgree = (id) => http.post(`/v1.0/agree/${id}`);

/**
 * 收藏/取消收藏视频
 * @param {*} id
 * @returns
 */
export const videoFollow = (id) => http.post(`/v1.0/follow/${id}`);

/**
 * 资源报错
 * @param {JSON} params
 * @returns
 */
export const videoError = (params) => http.post(`/v1.0/feedback/${params.goods_id}`, params)

/**
 * 会员捐助记录
 * @returns
 */
export const userRecord = () => http.get(`/v1.0/user/record`);

/**
 * 收藏记录
 * @param {*} params
 * @returns
 */
export const userFollows = (params) => http.get(`/v1.0/user/follows`, { params });

/**
 * 删除收藏
 * @param {*} id
 * @returns
 */
export const deleteFollow = (id) => http.delete(`/v1.0/user/follow/${id}`);

/**
 * 验证卡密
 * @param {*} code
 * @returns
 */
export const validUserLevelCode = (code) => http.post(`/v1.0/user/code`, { code });

/**
 * 修改密码
 * @param {*} params
 * @returns
 */
export const modifyPass = (params) => http.post(`/v1.0/user/password`, params);

