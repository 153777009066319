import axios from "axios";
import qs from "qs";
import { useUserStore } from "@/store/user";
import { message } from "ant-design-vue";

const http = axios.create({
    baseURL: "/api",
    timeout: 15000,
    withCredentials: true,
});

http.interceptors.request.use(
    (config) => {
        const store = useUserStore();
        config.paramsSerializer = (params) =>
            qs.stringify(params, { arrayFormat: "indices" });
        config.headers["Authorization"] = "Bearer " + store.token;
        return config;
    },
    (error) => {
        console.error("请求拦截发生错误:", error);
        return Promise.reject(error);
    }
);

http.interceptors.response.use(
    (response) => {
        // 正常响应
        return response;
    },
    (error) => {
        // 错误响应
        switch (error.response.status) {
            case 401:
                const userStore = useUserStore();
                userStore.logout();
                window.location.href = "/login";
                break;
            case 403:
                message.error(
                    error.response.data.message || "无此操作权限, 请联系管理员!"
                );
                break;
            case 404:
                message.error(
                    error.response.data.message || "服务不存在, 请重试!"
                );
                break;
            case 500:
                message.error(
                    error.response.data.message || "服务器发生错误, 请重试!"
                );
                break;
            default:
                message.error(
                    error.response.data.message || "未知错误, 请重试!"
                );
                break;
        }
        console.log("响应错误", error);
        return Promise.reject(error);
    }
);

export default http;
