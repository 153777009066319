import {defineStore} from "pinia";
import {useSystemStore} from "@/store/system";
import {login, register, getUserInfo} from "@/api";

export const TOKEY_KEY = "Token-Web";

export const useUserStore = defineStore("user", {
    state() {
        return {
            token: "",
            userInfo: null,
        };
    },

    actions: {
        /**
         * 账号登录
         * @param {*} params
         */
        async loginAttempt(params) {
            const {data: res} = await login(params);
            this.token = res.token;
            localStorage.setItem(TOKEY_KEY, this.token);
            await this.getUserInfo();
        },

        async registe(params) {
            const {data: res} = await register(params);
            this.token = res.token;
            localStorage.setItem(TOKEY_KEY, this.token);
            await this.getUserInfo();
        },

        /**
         * 获取登录用户信息
         */
        async getUserInfo() {
            try {
                const {data: res} = await getUserInfo();
                this.userInfo = res;
            } catch (err) {
                localStorage.removeItem(TOKEY_KEY);
            }

        },

        /**
         * 退出登录
         */
        async logout() {
            const system = useSystemStore();

            this.token = "";
            this.userInfo = null;

            system.loadedAsyncRoutes = false;
            localStorage.removeItem(TOKEY_KEY);
        },
    },
});
