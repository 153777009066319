import router from "@/router";
import { useTitle } from "@vueuse/core";

const buildTitle = (to) => {
    if (to.name === "CategoryVideo") {
        to.meta.title = window.CONFIG.categories.find(v => v.slug === to.params.category)?.name || "分类数据列表"
        to.matched.find(v => v.name === "CategoryVideo").meta.title = to.meta.title;
    } else if (to.name === "TagVideo") {
        to.meta.title = window.CONFIG.tags.find(v => v.slug === to.params.tag)?.name || "标签数据列表"
        to.matched.find(v => v.name === "TagVideo").meta.title = to.meta.title;
    } else if (to.name === "SearchVideo") {
        to.meta.title = to.query.t + " 搜索结果";
        to.matched.find(v => v.name === "SearchVideo").meta.title = to.meta.title;
    }
    return to.meta.title;
}

router.beforeEach((to) => {
    useTitle(buildTitle(to), {
        titleTemplate: `%s - ${window.CONFIG?.name}`,
    });
});
