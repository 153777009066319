import { useUserStore } from "@/store/user";

/**
 * 是否有指定操作权限
 * @param {*} actions
 * @returns
 */
export const hasAction = (actions = []) => {
    const store = useUserStore();
    const userActions = store.userInfo?.actions || [];

    return actions.some((action) => {
        return userActions.includes(action);
    });
};
