import { defineStore } from "pinia";
import { getCategories } from "@/api";

export const useSystemStore = defineStore("system", {
    state() {
        return {
            appName: window.CONFIG?.name || "纪录客",
            topMenuKey: [],
            leftMenuKey: [],
            collapsed: false,

            popMenu: false,

            loadedAsyncRoutes: false, // 是否已加载异步路由
            categories: window.CONFIG.categories ?? [], // 分类列表 - 顶部菜单
        };
    },

    actions: {
        async getCategories() {
            // TODO:: 缓存处理
            const { data: res } = await getCategories();
            this.categories = res;
        },

        async toggleMenu(bool)
        {
            this.popMenu = bool
        }
    },
});
