import { createRouter, createWebHistory } from "vue-router";
import { viewResolve } from "@/utils";

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes: [
        {
            path: "/register",
            name: "Register",
            component: viewResolve("views.Register.Index"),
            meta: {
                title: "账号注册",
            },
        },
        {
            path: "/login",
            name: "Login",
            component: viewResolve("views.Login.Index"),
            meta: {
                title: "账号登录",
            },
        },
        {
            path: "/auth-login",
            name: "AuthLogin",
            component: viewResolve("views.Login.Auth"),
            meta: {
                title: "直接登录",
            },
        },
        {
            path: "/",
            name: "Layout",
            component: viewResolve("views.Layout.Content"),
            meta: {
                title: "纪录客",
                visibleSider: false,
            },
            children: [
                {
                    path: "",
                    name: "Home",
                    component: viewResolve("views.Home.Index"),
                    meta: {
                        title: "首页",
                        hiddenBreadcrumb: true,
                    },
                },
                {
                    path: "/c/:category",
                    name: "CategoryVideo",
                    component: viewResolve("views.Video.CategoryVideo"),
                    meta: {
                        title: "{cate}纪录片",
                    },
                },
                {
                    path: "/t/:tag",
                    name: "TagVideo",
                    component: viewResolve("views.Video.TagVideo"),
                    meta: {
                        title: "{tag}",
                    },
                },
                {
                    path: "/s",
                    name: "SearchVideo",
                    component: viewResolve("views.Video.Search"),
                    meta: {
                        title: "搜索结果",
                    },
                },
                {
                    path: "/d/:id",
                    name: "VideoDetail",
                    component: viewResolve("views.Video.Detail"),
                    meta: {
                        title: "视频详情",
                    },
                },
                {
                    path: "/seek",
                    name: "Seek",
                    component: viewResolve("views.Seek.Index"),
                    meta: {
                        title: "求片之路",
//                         hiddenBreadcrumb: true,
                    },
                },
                {
                    path: "/about",
                    name: "About",
                    component: viewResolve("views.About.Index"),
                    meta: {
                        title: "关于",
                    },
                },
                {
                    path: "/404",
                    name: "NotFound",
                    component: viewResolve("views.Error.404"),
                    meta: {
                        title: "404 Not Found",
                        hiddenBreadcrumb: true,
                    },
                },
            ],
        },
        {
            path: "/",
            component: viewResolve("views.Layout.Index"),
            children: [
                {
                    path: "/uc",
                    name: "UserCenter",
                    component: viewResolve("views.UserCenter.Index"),
                    redirect: "/uc/profile",
                    children: [
                        {
                            path: "profile",
                            name: "Profile",
                            component: viewResolve("views.UserCenter.Profile"),
                            meta: {
                                title: "基本信息",
                                hiddenBreadcrumb: true,
                            },
                        },
                        {
                            path: "password",
                            name: "Password",
                            component: viewResolve("views.UserCenter.Password"),
                            meta: {
                                title: "修改密码",
                                hiddenBreadcrumb: true,
                            },
                        },
                        {
                            path: "follows",
                            name: "Follows",
                            component: viewResolve("views.UserCenter.Follows"),
                            meta: {
                                title: "我的收藏",
                                hiddenBreadcrumb: true,
                            }
                        },
                        {
                            path: "valid",
                            name: "Valid",
                            component: viewResolve("views.UserCenter.Valid"),
                            meta: {
                                title: "验证卡密",
                                hiddenBreadcrumb: true,
                            },
                        },
                        {
                            path: "record",
                            name: "Record",
                            component: viewResolve("views.UserCenter.Record"),
                            meta: {
                                title: "捐助记录",
                                hiddenBreadcrumb: true,
                            },
                        },
                    ],
                },
            ],
        },

        {
            path: "/:pathMatch(.*)",
            redirect: "/404",
        },
    ],
});

export default router;
