import router from "@/router";
import { useUserStore } from "@/store/user";

router.beforeEach(async (to, from) => {
    const userStore = useUserStore();

    const shouldRedirectLogin = (to) => {
        return to.path.includes("uc/") && !userStore.token;
    };

    const shouldFetchUserInfo = (to) => {
        return userStore.token && !userStore.userInfo;
    };

    if (shouldRedirectLogin(to)) {
        return { name: "Login" };
    }

    if (shouldFetchUserInfo(to)) {
        try {
            await userStore.getUserInfo();
        } catch (err) {
            return {
                name: "Login",
            };
        }
    }
});
