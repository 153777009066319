import { isRef, computed } from "vue";
import { Modal, message } from "ant-design-vue";

import { pick } from "lodash-es";

import Clipboard from "clipboard";

export * from "./tree";
export * from "./permission";

/**
 * 生成页面组件导入回调
 *
 * @param {String} path
 * @returns Function
 */
export const viewResolve = (path) => {
    const modules = import.meta.glob("../views/**/*.vue");
    let key = `../${path.replace(/\./g, "/")}.vue`;
    if (path === "LAYOUT") {
        key = "../views/Layout/Index.vue";
    }
    return modules[key];
};

/**
 * reactive 批量更新值
 * @param {*} reactive
 * @param {*} data
 */
export const reactiveAssign = (reactive, data) => {
    Object.assign(reactive, pick(data, Object.keys(reactive)));
};

/**
 * 数组选项转换为map
 * @param {*} data
 * @returns
 */
export const arr2map = (data) => {
    return data.reduce((acc, item) => {
        return { ...acc, [item.value]: item.label };
    }, {});
};

export const removeEmptyChildren = (data) => {
    for (const item of data) {
        if (item.children.length > 0) {
            removeEmptyChildren(item?.children);
        } else {
            delete item.children;
        }
    }
};

export const tap = (val, callback) => {
    callback(val);
    return val;
};

/**
 * 复制文本 @click='copyText('待复制文本')
 * @param {*} text
 * @param {*} event
 */
export const copyText = (text) => {
    try {
        Clipboard.copy(text);
        message.success("复制成功");
    } catch (e) {
        message.error("复制失败, 请手动复制");
    }
};

export const transaction = async (callback, spin = {}) => {
    try {
        spin[isRef(spin) ? 'value' : 'loading'] = true;
        await callback();
    } finally {
        spin[isRef(spin) ? 'value' : 'loading'] = false;
    }
};


export const confirmModal = async (content) => {
    return new Promise((resolve, reject) => {
        Modal.confirm({
            title: "系统提示",
            content: content,
            okText: "确认",
            cancelText: "取消",
            onOk: () => resolve(true),
            onCancel: () => reject(false)
        })
    })

}

export const fillUrl = (path) => {
    return import.meta.env.VITE_QINIU_DOMAIN + "/" + path;
}

export const warn = (...args) => {
    console.warn(`[documental warn]: `, ...args)
}

/**
 * 选择值换取Label
 * options [{value, label}]
 * // FIXME:: 是否有缓存的效果待定
 */
export const value2label = computed(() => (options) => (value, defaultLabel = '-') => options.find(v => v.value === value)?.label || defaultLabel)